import { MILESTONE_TYPE, MILESTONE_COMPLETION_FLOW, MILESTONE_APPROVAL_STATUS } from "../../constants/Milestone.const";
import { MILESTONE_STATUS_KEYS } from "../../constants/Statuses.const";
import { ComponentsState, IComponent, IMilestone } from "../../interfaces/Milestone.inteface";
import { ILineItem, ILineItemsState } from "../../interfaces/Workspace.interface";

export const checkMilestoneType = (milestone: IMilestone, milestoneType: MILESTONE_TYPE) => {
  return milestone?.type === milestoneType;
};

export const checkMilestoneCompletionFlow = (milestone: IMilestone, completionFlow: MILESTONE_COMPLETION_FLOW) => {
  return milestone?.completionFlow === completionFlow;
};

export const checkMilestoneStatus = (milestone: IMilestone, status: MILESTONE_STATUS_KEYS) => {
  return milestone?.status === status;
};

export const checkMilestoneApprovalStatus = (milestone: IMilestone, status: MILESTONE_APPROVAL_STATUS) => {
  return milestone?.approvalStatus === status;
};

export const getCurrentLinItemMilestones = (lineItem: ILineItem, lineItems: ILineItemsState) => {
  return lineItems?.find((itr) => itr?.lineItem?._id === lineItem?._id)?.milestones || [];
};

export const getNextMilestone = (component: IComponent, milestone: IMilestone, milestones: IMilestone[]) => {
  const currMilestoneIndex = component?.milestonesOrder.findIndex(
    (mil) => mil === milestone?.id || mil === milestone?._id
  );

  if (currMilestoneIndex >= 0 && milestones?.length) {
    const nextMilestoneId = component?.milestonesOrder[currMilestoneIndex + 1];
    return milestones?.find((mil) => mil?._id === nextMilestoneId) || null;
  }
  return null;
};

export const checkIsLastMilestone = (milestone: IMilestone, milestones: IMilestone[]) => {
  const milestoneIndex = milestones.findIndex((mil) => mil?._id === milestone?._id);
  return milestoneIndex === milestones.length - 1;
};

export const getListTypeMilestones = (milestones: IMilestone[]) => {
  return milestones.filter((milestone) => checkMilestoneType(milestone, MILESTONE_TYPE.LIST));
};

export const checkIsMilestoneDelayed = (milestone: IMilestone) =>
  milestone?.status !== MILESTONE_STATUS_KEYS.COMPLETE && milestone?.isDelayed;

export const combineMilestoneComponentsState = (components: ComponentsState) => [
  ...(components?.notReceived || []),
  ...(components?.inProgress || []),
  ...(components?.completed || []),
];
