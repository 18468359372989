export const COLORS = {
  blue_1: "#215EC9",
  blue_2: "#D4E5FE",
  blue_3: "#252525",
  blue_4: "#023B64",
  blue_5: "#186AD5",
  blue_6: "#839DC4",
  blue_7: "#EDF3FC",
  blue_8: "#D4E5FECC",
  blue_9: "#7E8FAB",
  blue_10: "#B2C5DF",
  blue_11: "#D4E4FF",
  blue_12: "#D4E5FF",
  blue_13: "#7D95B8",
  blue_14: "#47B2FF",

  white_1: "#FFFFFF",
  white_2: "#D9D9D9",
  white_3: "#F4F6FF",

  red_1: "#FF0000",
  red_2: "#BE000B",
  red_3: "#FFE0E1",
  red_4: "#FFF0F1",
  red_5: "#F5BFC2",
  red_6: "#FDE0E2",
  red_7: "#FFDFE1",
  red_8: "#FFF7F7",

  grey_1: "#FAFAFA",
  grey_2: "#647C9A",
  grey_3: "#868686",
  grey_4: "#B2C5DF",
  grey_5: "#f4f4f4",
  grey_6: "#EAEAEA",
  grey_7: "#DDE2E7",
  grey_8: "#7C7C7C",
  grey_9: "#8D8D8D",

  green_1: "#47C882",
  green_2: "#D6F5E6",
  green_3: "#EBFAF2",
  green_4: "#0CA65D",
  green_6: "#D9F4ED",

  yellow_1: "#F7AA36",
  yellow_2: "#F7BA36",
  yellow_3: "#FFEDD2",
  yellow_4: "#FFF8E5",
  yellow_5: "#F9D8BA",
  yellow_6: "#EB8F05",

  black_1: "#000000",
  black_2: "#1C1B1F",
} as const;
